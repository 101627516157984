
// colors
// blue primary - 3993E5
$primary: #3993E5;
$secondary: #F6D14B;
$link-color: #1469B7;
$bg-yellow-color: $secondary;
$bg-dark-blue-color: #1B293E;
$text-on-primary: #211f1a;
$error: #eb3616;
$active: #1F9741;

// sizes
$space: 10px;
$container-width: 1440px;
$desktop-step: 1240px;
$tablet-step: 768px;
$mobile-step: 414px;

// fonts

$font-condensed: 'Open Sans Condensed', sans-serif;
