@import '../../styles/variablesNew.scss';

.bl-pagination {
  list-style-type: none;
  margin-top: 10px !important;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  li {
    margin: 0 3px;
    padding: 2px;
    &.disabled {
      visibility: hidden;
      a {
        color: #aaa;
        cursor: default;
      }
    }
    &.active a {
      background-color: $secondary;

      &:hover {
        background-color: $secondary;
        color: black;
      }
    }
    a {
      line-height: 25px;
      height: 30px;
      width: 26px;
      border-radius: 4px;
      text-align: center;
      text-decoration: none;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:hover {
        background-color: $primary;
        color: white;

        svg path {
          fill: white;
        }
      }
    }
  }
}
