@import 'variables';
@import 'mixins';
@import 'include-media';
@import 'variablesNew.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&family=Open+Sans+Condensed:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Noto sans', sans-serif;
  height: 100vh;
  // overflow: auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

a {
  color: $link-color;
  &:hover {
    color: lighten($link-color, 10%);
  }
}

#__next {
  height: 100%;
}

nextjs-portal {
  display: none;
}

.btn {
  background-color: $secondary;
  border: 0;
  border-radius: 5px;
  color: $text-on-primary;
  padding: $space 2*$space;
  cursor: pointer;
  &:hover {
    background-color: lighten($secondary, 5%);
  }
  &:disabled {
    background-color: #e3e3e3;
    color: #555;
  }
}

.btn-outlined {
  border: 1px solid $secondary;
  background-color: transparent;
  &:hover {
    background-color: lighten($secondary, 30%);
  }
}

a.btn {
  display: inline-block;
  text-decoration: none;
  &:hover {
    color: $text-on-primary;
  }
}

.btn-small {
  padding: 0.5*$space $space;
}

.btn-medium {
  padding: 0.8*$space 1.2*$space;
  font-size: 0.9em;
}

.btn-large {
  padding: 1.2*$space 2*$space;
  font-size: 1.1em;
}

.topSpace1 {
  margin-top: 4*$space;
}

.success-message {
  display: inline-block;
  padding: 10px 30px;
  border: 2px solid #88d572;
  border-top-width: 6px;
  border-bottom-width: 6px;
  border-radius: 4px;
}


@import './blocks/pagination.scss';
